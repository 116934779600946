
import router from '@/router';
import {
  IonButtons,
  IonBackButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  alertController,
  actionSheetController ,
  
  } from '@ionic/vue';
  
import common from  "../js/common"
 import Download from '@/components/ScaricamentoContenuti.vue';
//import { defineComponent } from 'vue';
export default ({
  name: "langSwitch",
  props: {
    title: { type: String, default: 'Default Title' }
  },
  components: {
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    
  },
  data(){
    return{
      availableLangs:['it','en','fr','de'],
      saved:[],
      remaining:[],
      currLang:this.currentLang,
    }
  },
  setup(){
   
    return{
      
    }
  },
  computed:{

    

    savedLangs:{
        get() {
          console.log("linguee ", localStorage.getItem('savedLangs'))
          let savedLangs= [];
          savedLangs=  localStorage.getItem('savedLangs').split(",");
          this.assignSaved(savedLangs)
          console.log("linguee savedLangs ", savedLangs)
          return savedLangs
        },
      // setter
      set(newLang) {
        // Note: we are using destructuring assignment syntax here.
        this.saved.push(newLang);
        localStorage.setItem('savedLangs', this.saved)
      }

    },

    remainingLang(){
      const publishedLang=JSON.parse(localStorage.getItem('pubblication')).lang
     
      const myArray = publishedLang.filter( ( el ) =>{
        console.log("?? "+!this.savedLangs.includes( el ));
        return !this.savedLangs.includes( el);
      
      });
      this.assignRemaining(myArray);
    return myArray;

    }

    
  },
  created(){
    this.networkError=common.networkError;

    this.emitter.on('addLang', (lang)=>{
      this.addLang(lang);
    })

  },
  mounted(){
    this.savedLangs
    this.remainingLang

  
      this.currLang=localStorage.getItem("lang")
     

    
  },

  methods:{
    addLang(lang){
      console.log("ADDO LANG", lang)
      this.savedLangs=lang;
    },
    checkIfActive(lang){
      if(this.currLang==lang){
        return "checked"
      }
    },
    pushPage(passedLang) {
      const ionNav = document.querySelector('ion-nav') as any;
      
      ionNav.push(Download,  { lang: passedLang , fromC:"lang"});
     
      
      
    },
    buttons(){
      const remainingArray=[];
      const lang={};
        this.remaining.forEach(element => {

          lang["text"]= this.$t('menu.lang.'+element);
          lang[ "handler"]=() => {
                  console.log('clicked')
                  this.add(element)
          }
          remainingArray.push(lang)
        });
        const cancelbutton={
              text: this.$t('action.cancel'),
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked')
              },
            }
             remainingArray.push(cancelbutton)
        return remainingArray
      },
    async presentActionSheet() {
        const actionSheet = await actionSheetController.create({
            header: this.$t('menu.lang.select'),
            cssClass: 'my-custom-class',
            buttons: this.buttons(),
          });
        await actionSheet.present();

       await actionSheet.onDidDismiss();
        console.log('onDidDismiss');
      },

    async showOptions(lang) {
      const alert = await alertController.create({
      header: this.$t('menu.lang.add') ,
      message:  this.$t('menu.lang.alert') ,
      buttons: [
        {
          text: this.$t('action.download'),
          handler: () => {
            console.log("Accepted");
            if(window.navigator.onLine){
              const pubblication=JSON.parse(localStorage.getItem("pubblication"))
              const suppLang=pubblication.lang.find(el=> el==lang);
                //this.savedLangs=lang;
                this.remaining=this.remaining.filter(item => item !== lang);
              if(suppLang){
                  //this.searchMedia(lang);
                // this.$router.replace({ path: "/scarica/"+ lang});
                  this.pushPage(lang);
              }else{
                  //this.searchMedia('it');
                  //this.$router.replace({ path: "/scarica/it"});
                this.pushPage("it");
              }
            }else{
              console.log("NON C?èRETE");
              this.networkError();
            }
           
           
          },
        },
        {
          text: this.$t('action.cancel') ,
          role: "cancel",
        },
      ],
    });

    await alert.present();
    },


   

    switchLang(lang){
       /* if (this.$i18n.locale !== lang) {
           this.$i18n.locale = lang;
           localStorage.setItem('lang', lang);
        }*/
        if (localStorage.getItem('lang')!= lang) {
          localStorage.setItem('lang', lang);
          this.currLang=lang;
          this.$i18n.locale = lang;
        }
    },
    add(lang){

      this.showOptions(lang)
    /*  this.savedLangs=lang;
      this.remaining=this.remaining.filter(item => item !== lang);*/
    },
    assignSaved(savedLangs){
      this.saved=savedLangs;
    },
    assignRemaining(remainingLangs){
      this.remaining=remainingLangs;
      console.log("REMAINING ",  this.remaining.length);

    },
    searchMedia(lang){
      const schede=localStorage.getItem('dataMostra');
      const jsonSchede=JSON.parse(schede);
      let contenuto;
      console.log("->>", JSON.parse(schede));
      jsonSchede.forEach(scheda => {
        contenuto=scheda.content.find(el=> el.lang==lang)
        console.log("Cont ", contenuto);
        console.log("Conttype ", contenuto.type);
       if(contenuto.type=="audio") {
           /*this.mediaCounter();*/
          this.getmedia(contenuto.audio);
           console.log("Getaudio ")
        }else if(contenuto.type=="video"){
        /*  this.mediaCounter();*/
          this.getmedia(contenuto.video);
          console.log("Getvideo ")
        }else{
          console.log("Non ci sono Media per la scheda  in "+ lang)
          if(lang!= "it"){
            const contenutoit=scheda.content.find(el=> el.lang=='it');

             console.log("contenutoita "+ contenutoit.type)
            if(contenutoit.type=="audio") {
              /*this.mediaCounter();*/
              this.getmedia(contenutoit.audio);
              console.log("Getaudioita ")
            }else if(contenutoit.type=="video"){
            /*  this.mediaCounter();*/
              this.getmedia(contenutoit.video);
              console.log("Getvideoita ")
            }
          }
        }

      });
    },

    getmedia(name){
     console.log("numero di media "+ this.media );
     // fetch(this.$store.getters.baseUrl+"/inventario/download.php?id="+name+"&link=1")
      fetch(this.$store.getters.baseUrl+"/upload/"+name)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`)
        }
        console.log("OK Resp", response);
        if(response.status==200){
           console.log("OK 200");
        }
        return response
      })
      .catch(error => console.log(error))
    },

    /*pushPage() {
      const ionNav = document.querySelector('ion-nav') as any;
  
        ionNav.push(scaricamento, { title: 'Changeeee' });
  
    
    },*/

  }
})
