export const en= {
    action:{
        accept:"Accept",
        postponi:"Postpone",
        declina:"Decline",
        cancel:"Cancel",
        download:"Download",
        app:"Go to App",
        salta:"Skip",
        close:"Close"
    },
    onboard:{
        lang:{
            title:"Select",
            text:"in which language do you prefer to listen to the contents?"
        },
        tag:{
            title:"Bring the smartphone closer to the SilenceTags",
            text:"Use the APP to access the contents"
        },
        qr:{
            alternative:"Otherwise",
            title:"Scan",
            text:"Frame the QR Codes you will find along the way"
        },


    },
    networkerror:{
		title:"Network error",
        text:"The contents cannot be downloaded, the app will be available in online mode!",
        action:"Try later"
    },
    storageError:{
		title:"Insufficient space",
        text:"It's not possible to download all the contents, use the app in online mode!",
        action:"Ok"
    },
    update:{
		title:"Updates",
        text:"There are new updates available, do you want to update the app now?"
    },
    scarica:{
		title:"Download the contents",							 
        text:"The application will be available shortly, this may take some time."
    },
    main:{

        scan:"Scan QR code",
        start:"Scan SilenceTag",
        stop:"Stop SilenceTag",
        alertApp:{
            title:"Feature not available",
            text:"This feature is only available on the app",
        },
		timeoutTagTitle:"ATTENTION",
		timeoutTagText:"SilenceTag NOT detected, it may be off, use the \"Scan QR Code\" button",
		timeoutTagAccept:"Continue"
    
    },

    menu:{
        title:"Settings",
        content:{
            title:"Update content"
        },
        lang:{
            title:"Language",
            subtitile:"Dowloaded languages",
            add:"Add new language",
            select:"Available content",
            alert:"Attention this operation will download the content in the language selected",
            it:"Italian",
            en:"English",
            fr:"French",
            de:"German"
        },
        help:"Help",
        copyright:"Copyright",
        privacy:"Privacy",
        termini:"Terms of Service"
        
    },
    raccolta:{
        title:"Collection",
        subtitle:"Live the experience again",
        alerttext:"No content has been viewed yet. \n Come back at the tour's end to view the content found along the way."
    }
   
}