import { text } from "@fortawesome/fontawesome-svg-core";

export const it = {

    action:{
        accept:"Accetta",
        postponi:"Più tardi",
        declina:"Declina",
        cancel:"Annulla",
        download:"Scarica",
        app:"Apri in App",
        salta:"Salta",
        close:"Chiudi"
    },
    onboard:{
        lang:{
            title:"Scegli",
            text:"in quale lingua preferisci ascoltare i contenuti?"
        },
        tag:{
            title:"Avvicina lo smartphone ai SilenceTag",
            text:"Utilizza l'APP per accedere ai contenuti di approfondimento"
        },
        qr:{
            alternative:"Oppure",
            title:"Scansiona",
            text:"Inquadra i QR Code che troverai lungo il percorso"
        },


    },
    networkerror:{
		title:"Errore di rete",
        text:"Impossibile scaricare i contenuti, non sarà possibile utilizzare l'app in modalità offline!",
        action:"Riprova più tardi"
    },
    storageError:{
		title:"Spazio insufficiente",
        text:"Non è possibile scaricare tutti i contenuti, utilizzare l'app in modalità online!",
        action:"Ok"
    },
    update:{
		title:"Aggiornamento",
        text:"Sono disponibili nuovi contenuti, aggiornali prima di usare l'app"
    },
    scarica:{
		title:"Scaricamento dei contenuti",
        text:"A breve l'applicazione sarà disponibile, l'operazione può richiedere qualche minuto."
    },
    main:{

        scan:"Scansiona QR Code",
        start:"Scansiona Silence Tag",
        stop:"Stop SilenceTag",
		alertApp:{
            title:"funzione non disponibile",
            text:"Questa funzione è disponibile solo in app",
        },
		timeoutTagTitle:"ATTENZIONE",
		timeoutTagText:"SilenceTag NON rilevato, potrebbe essere spento, utilizza il pulsante \"Scansiona QR Code\"",
		timeoutTagAccept:"Prosegui"
    },

    menu:{
        title:"Impostazioni",
        content:{
            title:"Aggiorna contenuti"
        },
        lang:{
            title:"Lingue",
            subtitile:"Lingue scaricate",
            add:"Aggiungi nuova lingua",
            select:"Contenuti disponibili",
            alert:"Attenzione questa operazione scaricherà i contenuti nella lingua scelta",
            it:"Italiano",
            en:"Inglese",
            fr:"Francese",
            de:"Tedesco"
        },
        help:"Aiuto",
        copyright:"Copyright",
        privacy:"Privacy",
        termini:"Termini di servizio"

    },
    raccolta:{
        title:"Raccolta",
        subtitle:"Rivivi l'esperienza",
        alerttext:"Non hai ancora aperto le schede. \n Alla fine del tour potrai rivedere qui le scehde trovate lungo il percorso."
    }
   

}